<template>
  <div class="px-5 py-5 max-h-screen h-full flex items-center justify-center bg-gray-200 filter">
     <div elevation="2" class="bg-white rounded-md shadow-md px-5 py-5 w-full md:w-1/2 h-2/3 grid justify-around items-center">
   
         <div class="grid md:flex gap-5 justify-center text-center">
               <label class="text-4xl font-sans tracking-wider font-bold">COMPARTIR</label>
               <label class="text-4xl font-sans tracking-wider font-medium text-fucsia-lighter">EXPERIENCIA</label>
         </div>
         <div class="px-10 text-center justify-center items-center">
             <label class="text-xl tracking-wide text-gray-500 font-sans">Porfavor, espera. Seras redireccionado automaticamente</label>
              <v-progress-linear
                    color="light-blue darken-3"
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>
         </div>
     </div>

      <v-dialog 
        v-model="iserror"
        persistent
        max-width="400px"
        :retain-focus="false"
        >
        <v-link-error :message="message_error" :open="iserror" v-on:close="iserror = false" />
      </v-dialog>
  </div>
</template>

<script>
import sharedLinkService from '../../services/shared/sharedlink.service';
import verrorlink from './components/error.vue';

export default {
    props: {
        code: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: ""
        }
    },
    components: {
        'v-link-error' : verrorlink
    },
    data: function() {
        return {
            sharedlink: null,
            iserror: false,
            APP_URL: process.env.VUE_APP_URL,
            message_error: ''
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init: async function() {
            await this.getSharedLink(this.code, this.type);
        },
        getSharedLink: async function(code, type) {
            let response = await sharedLinkService.getByCode(code, type);
            if(response.success) {
                this.sharedlink = response.data;
                this.$router.push(`/${ this.sharedlink.path}`);
            }else {
                this.message_error = response.message;
                this.iserror = true;
            }
        }
    }

}
</script>

<style>

</style>